import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveTop from "../components/svg/curveTop"
import ArrowDown from "../components/svg/arrowDown"
import Exist from "../components/svg/exist"
import Consulting from "../components/svg/consulting"
import Coaching from "../components/svg/coaching"
import Benchmark from "../components/svg/benchmark"
import Process from "../components/svg/process"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Quotes from "../components/svg/quotes"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const DoPage = () => (

  	<Layout>

      <Seo 
         title="What we do | Media consulting services"
         description="Madclarity helps marketers with media benchmarking, media coaching and media strategy consulting services." 
      />

      <section role="main" className="bg">
         <div className="container">
            <div className="row">
               <div className="sub-hero-content sub-hero">
                  <div className="sub-hero-text">
                     <h1
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="400"
                     >What we <span className="blue">do</span></h1>
                     <p
                        data-sal="slide-up" 
                        data-sal-delay="300"
                        data-sal-duration="400"
                     >We help marketers improve the effectiveness and value of their media
                     spend via a unique combination of industry leading software, consulting
                     services and coaching programs.</p>
                     <div className="btn-row"
                        data-sal="slide-up" 
                        data-sal-delay="500"
                        data-sal-duration="400"
                     >
                        <button onClick={() => scrollTo('#consulting')} className="btn-scroll">  
                            <ArrowDown />    
                        </button>
                     </div>
                  </div>
                  <div className="sub-hero-img shi-height img-flip">
                     <Exist />
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-btm">
         <CurveTop />
      </div>

      <section className="section-pad" id="consulting">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Consulting />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h2>Consulting</h2>
                        <p className="lead">As marketers, you need clear and direct answers to complex media advertising questions. Advertising is a vital part of your business and a significant cost.</p>
                        <p className="lead">Madclarity plays a crucial role in helping and challenging our clients, to ensure that the right choices are being made and the right KPIs being set, for the right reasons.</p>
                        <p className="lead">We develop tailored solutions for you, answering your questions honestly, directly and constructively. We help you solve problems, using our decades of experience from around the globe.</p>
                        <p className="lead">Our goal is to make the media advertising part of your life clearer and more accountable. To remove the guesswork and help you make difficult decisions with confidence.</p>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section id="benchmarking">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2 order-2-mob"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h3>Media <span className="blue">Benchmarking</span></h3>
                        <p className="lead">Our Media Benchmarking challenges our clients as much as their agencies. It replaces hoping with knowing.</p>
                        <p className="lead">It is clear, objective and constructive and is based on a multi-client data base, comprising real spends with independently-sourced audience and appearance data.</p>
                        <p className="lead">It informs and recommends opportunities for continual improvement of effectiveness, performance and value. TV and Online performance is reviewed jointly. The focus of Online is on brand-building, rather than direct response metrics.</p>
                        <p className="lead">Madclarity is the only consultancy benchmarking the most meaningful brand-building media metric - <strong>the cost of effective reach</strong>.</p>
                    </div>
                    <div className="col-2 order-1-mob">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Benchmark />
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section className="section-pad" id="coaching">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Coaching />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h2>Coaching</h2>
                        <p className="lead">Successful coaching is not lecturing. It helps people improve their thinking.</p>
                        <p className="lead">Our workshops are interactive and engaging, covering all stages of the campaign development process. Informal and unthreatening, they help your marketing team make difficult decisions with confidence.</p>
                        <p className="lead">Media is not a marketer’s core skill and training courses in isolation can create frustration. So we developed a programme of workshops, spread through the year, supported by Madclarity’s on-going coaching and consultancy.</p>
                        <p className="lead">Read more about our <Link to="/faulkner-media-training">media training programme</Link></p>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section className="section-pad" id="process">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2 order-2-mob"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h4>Process <span className="blue">Evaluation</span></h4>
                        <p className="lead">Process is not the sexiest word in the marketing dictionary, but getting it wrong results in wasting millions of advertising and marketing dollars.</p>
                        <p className="lead">Using our extensive experience with numerous businesses across the Asia Pacific region, we investigate and analyse the way your advertising expenditure is being managed. We deep dive into your entire comms development process, from brand plans and budget allocation through to measurement and evaluation.</p>
                        <p className="lead">We make recommendations for performance improvement on vital areas, including briefing focus, strategy insight & creativity, people skills and implementing learning.</p>
                    </div>
                    <div className="col-2 order-1-mob">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Process />
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <div className="container section-pad-btm">
         <div className="row">
            <div className="col-middle-wrapper">
               <div className="col-middle">
                  <Link to="/why-we-exist" className="btn btn-lg btn-middle">Why we exist</Link>
               </div> 
            </div>
         </div>
      </div>

      <div className="curve-top flip">
         <CurveTop />
      </div>
      <section className="bg testimonials">
         <div className="container">
            <div className="row">
               <div className="testimonial-col-wrapper">
                  <div className="testimonial-col"
                     data-sal="fade" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                  >
                     <Quotes />
                     <div className="test-excerpt">
                        ... tough questions ...
                        <span className="blue"> workable solutions</span> ...
                     </div>
                     <Testimonial
                        quote="Madclarity plays a vital part in monitoring and directing our media investment strategy. They ask the tough questions and through their knowledge base, always provide viable and workable solutions for improvements."
                        name="Scott Baird"
                        title="Chief Marketing Officer, Hungry Jack’s Pty Ltd"
                     />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div className="curve-btm flip">
         <CurveTop />
      </div>
      
      <Cta />

  </Layout>
)

export default DoPage