import React from "react"

const Process = () => (
    <svg width="212" height="177" viewBox="0 0 212 177" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a hand dropping a coin into another hand</desc>
        <path d="M43.1 161.8C31.4 164.6 18.1 163.3 9.00004 155.5C-0.0999622 147.7 -2.89996 132.4 5.10004 123.4C12.6 115 26.7 113.9 32.4 104.2C36.5 97.2 34.5 88.1 30.8 80.9C27.1 73.7 21.8 67.2 19.1 59.5C14.4 46.1 18.7 30.5 28.1 19.8C37.5 9.20002 51.5 3.20001 65.6 1.40001C79.7 -0.399992 94.1 1.70001 107.9 5.20001C114.4 6.90001 121 8.90002 126.7 12.6C132.4 16.3 137.1 21.9 138.3 28.5C139.8 36.3 136.6 45.1 140.7 51.9C144.9 59 154.3 60.1 162.3 61.9C179.4 65.6 195.4 75.7 204.3 90.6C213.2 105.5 214.4 125.5 205.5 140.5C196.6 155.5 177.2 164.1 160.5 158.8C151.9 156.1 144.6 150.3 136.6 146.2C119.9 137.8 101.5 138.5 84.3 144.5C70.2 149.3 57.5 158.3 43.1 161.8Z" fill="#eff1f6"/>
        <path d="M61.4999 32.7C61.8999 32.3 61.8999 31.7 61.4999 31.2C61.0999 30.8 60.4999 30.8 59.9999 31.1C59.5999 31.5 59.5999 32.1 59.9999 32.6C60.3999 33.1 60.9999 33.1 61.4999 32.7ZM56.2999 32.8C56.6999 32.4 56.6999 31.8 56.2999 31.3C55.8999 30.9 55.2999 30.9 54.7999 31.3C54.3999 31.7 54.3999 32.3 54.7999 32.8C55.2999 33.1 55.8999 33.1 56.2999 32.8ZM65.7999 62.2V65.4H43.7V25.8L65.5999 25.7V31.3L65.7999 62.2Z" fill="#61D0EC"/>
        <path d="M155.6 39.1C168 36.9 160.3 32.6 150.2 29.2C140.1 25.8 121.7 24.4 121.7 24.4C121.7 24.4 126.1 23.3 130.4 21.9C134.7 20.5 137 15.5 135.2 14.8C133.4 14.1 126.9 15.4 120.7 16.2C118.3 16.5 115.7 17.6 113.4 18.7C108.6 21.1 103.6 23 98.4001 24.2C95.9001 24.8 92.9001 25.5 89.8001 26.5C84.1001 28.3 74.4001 30.2 65.6001 31.3L65.7001 62.3C71.0001 64.5 76.0001 66.8 80.4001 68C89.0001 70.3 93.2001 73.4 98.8001 77.3C104.4 81.2 113.4 88.1 119.3 90.9C123.9 93.1 129.3 90.7 128.3 85.8C128 84.4 127.3 82.9 125.8 81.2C119.3 73.5 104 65.2 101.2 58.5C99.4001 54 100.8 48.3 104.9 45.6C109 42.9 113.4 44.4 117.7 46C121.9 47.6 126.7 47.9 130.2 51C135.5 55.7 136 63.6 140.3 69C141.2 70.1 142.9 71.9 144.5 72.8C146.3 73.8 148 73.7 148.3 70.4C149 62.1 135.3 39.2 135.3 39.2C135.3 39.2 145.2 42.5 150 46.9C161.1 56.9 168.3 56.3 166.7 51.3C165 45.7 155.6 39.1 155.6 39.1Z" fill="white"/>
        <path d="M146.7 74.7C146 74 145.3 73.4 144.5 72.9C140.5 70.4 135.2 70.8 131.6 74.1C128.4 77.1 127.4 81.6 128.9 85.5C129.4 86.8 130.1 88.1 131.1 89.1C135.1 93.4 141.8 93.6 146.1 89.6C150.4 85.7 150.7 79 146.7 74.7Z" fill="#61D0EC"/>
        <path d="M54.9001 31.2C54.5001 31.6 54.5001 32.2 54.9001 32.7C55.3001 33.1 55.9001 33.1 56.4001 32.7C56.9001 32.3 56.8001 31.7 56.4001 31.2C56.0001 30.9 55.3001 30.9 54.9001 31.2Z" fill="#272223"/>
        <path d="M60 31.2C59.6 31.6 59.6 32.2 60 32.7C60.4 33.1 61 33.1 61.5 32.8C62 32.5 61.9 31.8 61.5 31.3C61.1 30.8 60.5 30.8 60 31.2Z" fill="#272223"/>
        <path d="M144.5 72.9C145.3 73.4 146 74 146.7 74.7C150.7 79 150.5 85.7 146.2 89.7C141.9 93.7 135.2 93.5 131.2 89.2C130.2 88.1 129.5 86.9 129 85.6C127.6 81.7 128.5 77.2 131.7 74.2C135.2 70.8 140.5 70.4 144.5 72.9Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M65.7999 62.2C71.0999 64.4 76.0999 66.7 80.4999 67.9C89.0999 70.2 93.2999 73.3 98.8999 77.2C104.5 81.1 113.5 88 119.4 90.8C124 93 129.4 90.6 128.4 85.7C128.1 84.3 127.4 82.8 125.9 81.1C119.4 73.4 104.1 65.1 101.3 58.4C99.4999 53.9 100.9 48.2 105 45.5C109.1 42.8 113.5 44.3 117.8 45.9C122 47.5 126.8 47.8 130.3 50.9C135.6 55.6 136.1 63.5 140.4 68.9C141.3 70 143 71.8 144.6 72.7C146.4 73.7 148.1 73.6 148.4 70.3C149.1 62 135.4 39.1 135.4 39.1C135.4 39.1 145.3 42.4 150.1 46.8C161.2 56.8 168.4 56.2 166.8 51.2C165 45.7 155.7 39 155.7 39C168.1 36.8 160.4 32.5 150.3 29.1C140.2 25.7 121.8 24.3 121.8 24.3C121.8 24.3 126.2 23.2 130.5 21.8C134.8 20.4 137.1 15.4 135.3 14.7C133.5 14 127 15.3 120.8 16.1C118.4 16.4 115.8 17.5 113.5 18.6C108.7 21 103.7 22.9 98.4999 24.1C95.9999 24.7 92.9999 25.4 89.8999 26.4C84.1999 28.2 74.5 30.1 65.7 31.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.6 39.1C155.6 39.1 148.7 34.9 143.3 32.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M135.3 39.1C135.3 39.1 130.3 36 120 33.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M109.6 80.4C109.9 79.3 110.6 78.3 111.6 77.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M110.1 73.6C108.1 74.3 106.6 76.4 106.6 78.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M141.4 58.8C139.6 58.4 137.6 59.1 136.4 60.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M130.4 50.6C131 49.4 132.2 48.5 133.5 48.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M138.7 39.6C139.2 38.3 139.6 37.1 140.1 35.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M141 40.5C141.3 39.8 141.7 39 142 38.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.7 47.7C153.1 46.7 153.5 45.7 153.9 44.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M115.8 32.3C113.3 31.2 110.7 30.1 108.2 29.1C107 28.6 105.8 28.1 104.5 27.9C101.8 27.6 99.2 28.9 96.8 30.1" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M65.8 62.2V65.4H43.8V25.8L65.7001 25.6V31.2L65.8 62.2Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M65.5001 35.6L53.6001 35.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M130.9 125.9C130.9 125.9 155.5 116.2 163.6 112.2C171.7 108.1 173 117.4 163.6 124.4C154.2 131.4 139.2 144.9 127.6 147.7C116 150.5 89.4001 149.9 83.1001 149.5C76.9001 149.1 65.8001 156.1 65.8001 156.1L64.1001 153.9L47.1001 131.4C47.1001 131.4 54.0001 128.7 56.8001 123.6C59.6001 118.6 66.5001 112 80.1001 115.4C93.6001 118.7 95.7001 122.3 108.9 122.3C120.7 122.3 128.6 122 130.9 125.9Z" fill="white"/>
        <path d="M54.9 157.9C55 157.3 54.6 156.7 54 156.6C53.4 156.5 52.8 156.9 52.7 157.5C52.6 158.1 53 158.7 53.6 158.8C54.2 158.9 54.8 158.5 54.9 157.9ZM59.4 154.5C59.5 153.9 59.1 153.3 58.5 153.2C57.9 153.1 57.3 153.5 57.2 154.1C57.1 154.7 57.5 155.3 58.1 155.4C58.7 155.5 59.2 155.2 59.4 154.5ZM65.9 156.1L70 161.7L54.2 175.3L29.5 139.8L45.1 128.6L47.1 131.4L64.1 153.9L65.9 156.1Z" fill="#61D0EC"/>
        <path d="M58.4999 153.2C59.0999 153.3 59.4999 153.9 59.3999 154.5C59.2999 155.1 58.6999 155.5 58.0999 155.4C57.4999 155.3 57.0999 154.7 57.1999 154.1C57.2999 153.5 57.8999 153.1 58.4999 153.2Z" fill="#272223"/>
        <path d="M53.9999 156.5C54.5999 156.6 54.9999 157.2 54.8999 157.8C54.7999 158.4 54.1999 158.8 53.5999 158.7C52.9999 158.6 52.5999 158 52.6999 157.4C52.7999 156.8 53.3999 156.4 53.9999 156.5Z" fill="#272223"/>
        <path d="M130.9 125.9C130.9 125.9 155.5 116.2 163.6 112.2C171.7 108.1 173 117.4 163.6 124.4C154.2 131.4 139.2 144.9 127.6 147.7C116 150.5 89.4001 149.9 83.1001 149.5C76.9001 149.1 65.8001 156.1 65.8001 156.1L64.1001 153.9L47.1001 131.4C47.1001 131.4 54.0001 128.7 56.8001 123.6C59.6001 118.6 66.5001 112 80.1001 115.4C93.6001 118.7 95.7001 122.3 108.9 122.3C120.7 122.3 128.6 122 130.9 125.9ZM130.9 125.9C131.2 126.4 131.4 126.9 131.5 127.6C132.5 133.3 117.1 137.9 94.6001 134" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M47.2 131.4L45.1 128.6L29.5 139.8L54.2 175.3L70 161.7L65.9 156.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M111.4 126.2C110.8 127.9 110.8 129.8 111.3 131.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M106.8 125.3C106 127.1 105.8 129.1 106.2 131" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.3999 133.4C58.0999 132.1 59.3999 130.3 60.6999 128.6C61.9999 126.9 63.2999 125.1 65.0999 123.9C66.8999 122.7 69.1999 122.1 71.1999 122.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M92.0999 144C85.8999 142.6 79.1999 143.2 73.3999 145.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M64.2 153.9L54.2 162.4" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

)

export default Process